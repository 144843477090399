import { AlertType } from '@vismaux/react-nc4';
import React, { createContext, useContext, useState } from 'react';

export interface IToast {
    id: number;
    title: string;
    toastType: AlertType;
}

const DEFAULT_VISIBILITY_TIME = 5 * 1000; // 5 seconds

export type INewToast = Omit<IToast, 'id'>;

interface IToastContext {
    toastId: number;
    toasts: IToast[];
    addToast: (newToast: INewToast) => IToast;
    removeToast: (toastIdToRemove: number) => void;
}

interface IToastContextProps {
    children: React.ReactNode;
}

const ToastContext = createContext<IToastContext>({
    toastId: 0,
    toasts: [],
    addToast: () => ({}) as IToast,
    removeToast: () => null,
});

const ToastContextProvider = (props: IToastContextProps) => {
    const [toastId, setToastId] = useState(1);
    const [toasts, setToasts] = useState<IToast[]>([]);

    const addToast = (newToast: INewToast) => {
        const toastToAdd: IToast = {
            id: toastId,
            title: newToast.title,
            toastType: newToast.toastType,
        };

        setToasts(toasts => [...toasts, toastToAdd]);
        setToastId(previousId => previousId + 1);
        return toastToAdd;
    };

    const removeToast = (toastToRemoveId: number) => {
        setToasts(toastList => {
            return toastList.filter(toast => toast.id !== toastToRemoveId);
        });
    };

    const contextValue = {
        toastId: 1,
        toasts: toasts,
        addToast: (newToast: INewToast) => addToast(newToast),
        removeToast: (toastToRemoveId: number) => removeToast(toastToRemoveId),
    };

    return <ToastContext.Provider value={contextValue}>{props.children}</ToastContext.Provider>;
};

const useToast = () => {
    const { toasts, addToast, removeToast } = useContext(ToastContext);

    // Create new toast and set timeout when toast is deleted automatically
    function createToast(toast: INewToast) {
        const addedToast = addToast(toast);
        if (addedToast) {
            setTimeout(() => removeToast(addedToast.id), DEFAULT_VISIBILITY_TIME);
        }
    }

    return { createToast, toasts };
};

export { ToastContextProvider, useToast };
