// Vendors
import React from 'react';
import { useTranslation } from 'react-i18next';

const EmptySelection = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="no-discussion-thread-selected">
                <strong>{t('no-discussion-thread-selected-title')}</strong>
            </div>
            <div className="no-discussion-thread-selected">
                <span>{t('no-discussion-thread-selected-desc')}</span>
            </div>
        </>
    );
};

export default EmptySelection;
