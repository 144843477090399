// Vendors
import React from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { IDiscussionThreadWithMessageIds } from '@appTypes/discussionthread';
// Other
import { getSentAtMessage, getUserDisplayName } from '@common-utils';

interface Props {
    discussionThread: IDiscussionThreadWithMessageIds;
}

export const getNewestMessageSentAt = (latestActivity: Date) => {
    return getSentAtMessage(latestActivity, false);
};

export const DiscussionThreadListItem = (props: Props) => {
    const { t } = useTranslation();
    const discussionThread = props.discussionThread;

    return (
        <>
            {discussionThread ? (
                <div
                    className="row discussionThreadListItem"
                    aria-label={t('labels.discussionThreadItem')}>
                    <div className="col-md-12">
                        <span>{getUserDisplayName(discussionThread.author)}</span>
                        <div className="row">
                            <div className="col-8 truncate">{discussionThread.name}</div>
                            <div className="col-4 date">{getNewestMessageSentAt(discussionThread.lastActivity)}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>{t('discussionThreadMissing')}</div>
            )}
        </>
    );
};
