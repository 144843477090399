// Vendors
import { Spinner } from '@vismaux/react-vud';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// Types
import { IGradebook } from '@appTypes/recipients';
import { LoadingState } from '@appTypes/loadingstates';
// Services
import RecipientService from '@services/RecipientService';
// Components
import Gradebooks from './Gradebooks';

type GradebookLoaded = {
    gradebook?: IGradebook;
    loadingState: LoadingState;
};

const GradebookContainer = () => {
    const { t } = useTranslation();
    const [currentGradebooks, setCurrentGradebooks] = useState<GradebookLoaded>({ loadingState: LoadingState.Loading });
    const [pastGradebooks, setPastGradebooks] = useState<GradebookLoaded>({ loadingState: LoadingState.Loading });
    const [futureGradebooks, setFutureGradebooks] = useState<GradebookLoaded>({ loadingState: LoadingState.Loading });

    useEffect(() => {
        (async () => {
            try {
                const currentGradebookResponse = await RecipientService.getCurrentGradebooks();
                setCurrentGradebooks({ gradebook: currentGradebookResponse, loadingState: LoadingState.Done });
            } catch (e) {
                console.error(e);
                setCurrentGradebooks({ loadingState: LoadingState.Error });
            }
            try {
                const futureGradebookResponse = await RecipientService.getFutureGradebooks();
                setFutureGradebooks({ gradebook: futureGradebookResponse, loadingState: LoadingState.Done });
            } catch (e) {
                console.error(e);
                setFutureGradebooks({ loadingState: LoadingState.Error });
            }
            try {
                const pastGradebookResponse = await RecipientService.getPastGradebooks();
                setPastGradebooks({ gradebook: pastGradebookResponse, loadingState: LoadingState.Done });
            } catch (e) {
                console.error(e);
                setPastGradebooks({ loadingState: LoadingState.Error });
            }
        })();
    }, []);

    return (
        <>
            {currentGradebooks.loadingState === LoadingState.Done && currentGradebooks.gradebook && (
                <Gradebooks
                    gradeBooks={currentGradebooks.gradebook}
                    gradebookHeader={t('selectRecipients.currentTeachingGroups')}
                />
            )}
            {currentGradebooks.loadingState === LoadingState.Error && (
                <span className="error-text">{t('errors.currentGradeboooksFetchFailed')}</span>
            )}
            {futureGradebooks.loadingState === LoadingState.Done && futureGradebooks.gradebook && (
                <Gradebooks
                    gradeBooks={futureGradebooks.gradebook}
                    gradebookHeader={t('selectRecipients.futureTeachingGroups')}
                />
            )}
            {futureGradebooks.loadingState === LoadingState.Error && (
                <span className="error-text">{t('errors.futureGradeboooksFetchFailed')}</span>
            )}
            {pastGradebooks.loadingState === LoadingState.Done && pastGradebooks.gradebook && (
                <Gradebooks
                    gradeBooks={pastGradebooks.gradebook}
                    gradebookHeader={t('selectRecipients.pastTeachingGroups')}
                />
            )}
            {pastGradebooks.loadingState === LoadingState.Error && (
                <span className="error-text">{t('errors.pastGradeboooksFetchFailed')}</span>
            )}
            {(currentGradebooks.loadingState === LoadingState.Loading ||
                pastGradebooks.loadingState === LoadingState.Loading ||
                futureGradebooks.loadingState === LoadingState.Loading) && (
                <div className="center">
                    <Spinner />
                </div>
            )}
        </>
    );
};

export default GradebookContainer;
