import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AxiosCacheInstance, buildMemoryStorage, CacheOptions, setupCache } from 'axios-cache-interceptor';

const messagingAxiosConfig: AxiosRequestConfig = {
    baseURL: '',
};

const defaultCacheOptions: CacheOptions = {
    ttl: 1000 * 60 * 5, // Cache for 5 minutes
    methods: ['get'], // Cache GET requests
    storage: buildMemoryStorage(),
    cachePredicate: {
        statusCheck: status => status === 200, // Cache only successful responses
    },
};

export const messagingHttpClient: AxiosInstance = axios.create(messagingAxiosConfig);
export const messagingHttpClientCached: AxiosCacheInstance = setupCache(
    axios.create(messagingAxiosConfig),
    defaultCacheOptions
);

clientConfig(messagingHttpClient);
clientConfig(messagingHttpClientCached);

function clientConfig(client: AxiosInstance | AxiosCacheInstance) {
    client.interceptors.request.use(config => {
        const slug = window.frameElement?.getAttribute('data-slug');
        const baseUri = window.frameElement?.getAttribute('data-url');

        if (baseUri) {
            config.headers = config.headers || {};
            config.headers['Content-type'] = 'application/json';
            config.headers['X-WilmaUrl'] = baseUri;
            config.headers['X-WilmaSlug'] = slug || '';
        }

        return config;
    });
}
