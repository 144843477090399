// Vendors
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IUser } from '@appTypes/user';
import { Icon } from '@vismaux/react-vud';
import * as amplitude from '@amplitude/analytics-browser';
import { useLocation, useParams } from 'react-router-dom';
// Context
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Types
import { IContent } from '@appTypes/content';
import { IMessageWithContent } from '../@types/message';
// Services
import DiscussionThreadService from '@services/DiscussionThreadService';
// Hooks
import { useUpdateInboxUnreadCount } from './hooks/useUpdateInboxUnreadCount';
// Higher Order Components
import withReplyHandling, { WithReplyHandlingProps } from './hoc/withReplyHandling';
// Components
import { DiscussionThreadUserAdded } from './DiscussionThreadUserAdded';
// Other
import {
    getSentAtMessage,
    getThreadUnreadMessages,
    getUserDisplayName,
    threadHasMessagesFromMultipleAuthors,
} from '@common-utils';

const DiscussionThread = ({ replyTo }: WithReplyHandlingProps) => {
    const { t } = useTranslation();
    const {
        state: { currentUser, unreadMessages, activeDiscussionThread, activeThreadMessages },
        dispatch,
    } = MessagingState();
    const messagesStartRef = useRef<null | HTMLDivElement>(null);
    const messageNewRef = useRef<null | HTMLDivElement>(null);
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();
    //TODO zero until pagination canReplyToThisMessage solution is found, currently frontend only supports one page of messages
    const location = useLocation();
    const params = useParams();
    const threadId = activeDiscussionThread?.id;
    const lastReadMessageId = activeDiscussionThread?.lastReadMessageId;

    useEffect(() => {
        if (messageNewRef.current != null) {
            messageNewRef.current.scrollIntoView({ block: 'end' });
            messageNewRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
        } else {
            messagesEndRef.current?.scrollIntoView();
        }
    }, [activeDiscussionThread, activeThreadMessages]);

    useEffect(() => {
        if (threadId == null || !activeThreadMessages || activeThreadMessages.length === 0) {
            return;
        }

        document.getElementById('message-list')?.focus();
        async function updateReadStatus(id: number, lastReadMessageId: number, messages: IMessageWithContent[]) {
            const latestMessage = messages.slice(-1)[0];
            const unreadMessages = getThreadUnreadMessages(messages, lastReadMessageId);
            if (unreadMessages.includes(latestMessage.id)) {
                const responseOk = await DiscussionThreadService.setMessageAsRead(id, latestMessage.id);
                if (responseOk) {
                    dispatch({
                        type: ActionType.SET_MESSAGE_AS_READ_SUCCESS,
                        payload: {
                            threadId: id,
                            messageId: latestMessage.id,
                        },
                    });
                }
                // Re-fetch inbox unread count when marking thread as read, naively.
                // This might desync the count and showing received threads, when for example someone sends a new thread while the user is already
                // reading another unread thread. The read thread is marked read, but the count will be increased and no new threads would be shown.
                // Refactor this later, use for example WebSockets to communicate current thread listing statuses to frontend.
                updateInboxUnreadCount();
            }
        }
        updateReadStatus(threadId, lastReadMessageId || 0, activeThreadMessages);
        amplitude.track('message_opened', {
            discussion_thread_id: threadId,
        });
    }, [
        dispatch,
        updateInboxUnreadCount,
        location,
        params.folderParam,
        threadId,
        lastReadMessageId,
        activeThreadMessages,
        currentUser,
    ]);

    const replyToThisMessage = (messageId: number, userToReplyTo: IUser) => {
        activeDiscussionThread && replyTo(messageId, userToReplyTo);
    };

    return (
        <div
            tabIndex={0}
            className="messageList"
            id="message-list">
            {activeThreadMessages?.map((message: IMessageWithContent, i: number) => {
                const messageDivClass = classNames({
                    message: activeThreadMessages?.length <= 1,
                    'bubble-right': activeThreadMessages?.length > 1 && message.author.roleGuid === currentUser?.roleGuid,
                    'bubble-left': activeThreadMessages?.length > 1 && message.author.roleGuid !== currentUser?.roleGuid,
                });

                const messageRef =
                    i === 0 ? messagesStartRef : i + 1 === activeThreadMessages.length ? messagesEndRef : null;

                const getReplyBtn = (message: IMessageWithContent) => {
                    return (
                        <button
                            type="button"
                            onClick={() => replyToThisMessage(message?.id, message.author)}
                            className="btn btn-default icon-button reply-button-right">
                            <Icon
                                name="send"
                                dynamic
                                size="sm"
                            />
                        </button>
                    );
                };

                return (
                    <React.Fragment key={message.id}>
                        {unreadMessages.length > 0 && i !== 0 && message.id === unreadMessages[0] && (
                            <div
                                key={'unreadMessages'}
                                className="unread-container"
                                ref={messageNewRef}>
                                <hr />
                                <div className="unread">{t('message.newMessage', { count: unreadMessages.length })} </div>
                            </div>
                        )}
                        <div
                            className={messageDivClass}
                            ref={messageRef}>
                            {threadHasMessagesFromMultipleAuthors(
                                activeThreadMessages,
                                activeDiscussionThread?.author?.roleGuid
                            ) &&
                                message?.canReplyToThisMessage &&
                                activeThreadMessages.length > 1 &&
                                getReplyBtn(message)}
                            {message.contents.map((content: IContent, index: number) => {
                                return (
                                    <div
                                        className="container-fluid"
                                        key={index}>
                                        {activeThreadMessages.length > 1 && (
                                            <div className="row">
                                                <div className="col-6">
                                                    <b>{getUserDisplayName(message.author)}</b>
                                                </div>
                                                <div className="col">
                                                    <p className="float-right">{getSentAtMessage(message.sentAt)}</p>
                                                </div>
                                            </div>
                                        )}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span>{content.contentString}</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <DiscussionThreadUserAdded
                            threadMessages={activeThreadMessages}
                            threadRecipients={activeDiscussionThread?.recipients || []}
                            message={message}
                            index={i}
                        />
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default withReplyHandling(DiscussionThread);
