// Vendors
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorPage, ErrorType } from '@vismaux/react-vud';

interface ICustomErrorPageProps {
    type: ErrorType;
    title: string;
    buttonCaption: string;
    redirectUrl: string;
}

// VUD ErrorPage component button uses 'window.assign()' in redirect button, which does not work correctly. Replace button with react router navigate function.
const CustomErrorPage = ({ type, title, buttonCaption, redirectUrl }: ICustomErrorPageProps) => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(`${redirectUrl}`);
    };

    return (
        <ErrorPage
            type={type}
            title={title}>
            <button
                className="btn btn-primary"
                onClick={handleButtonClick}>
                {buttonCaption}
            </button>
        </ErrorPage>
    );
};

export default CustomErrorPage;
