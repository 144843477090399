// Vendors
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Services
import FolderService from '@services/FolderService';
// Components
import DiscussionThreadPagination from './DiscussionThreadPagination';

const DiscussionThreadList = () => {
    const { t } = useTranslation();
    const { folderParam } = useParams();
    const activeFolder = FolderService.getAll().find(folder => folder.path === folderParam);

    return (
        <>
            <ul className="list-group full-height">
                <li className="list-group-item list-group-title">
                    <div className="row">
                        <div>
                            <span className="discussion-thread-list-group-title">
                                {activeFolder && t(activeFolder.name)}
                            </span>
                        </div>
                    </div>
                </li>
                <DiscussionThreadPagination />
            </ul>
        </>
    );
};

export default DiscussionThreadList;
