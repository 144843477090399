// Vendors
import classNames from 'classnames';
import React, { useState } from 'react';
import { Icon } from '@vismaux/react-vud';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// Context
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Types
import { LoadingState } from '@appTypes/loadingstates';
import { IDiscussionThread, IDiscussionThreadWithMessageIds } from '@appTypes/discussionthread';
// Components
import DiscussionThreadMenu from './DiscussionThreadMenu';
import QuickAddRecipients from './QuickAddRecipients';
// Other
import { getSentAtTitle, getUserDisplayName } from '@common-utils';

interface Props {
    discussionThread: IDiscussionThread | IDiscussionThreadWithMessageIds;
}

const DiscussionTitle = ({ discussionThread }: Props) => {
    const { t } = useTranslation();
    const [isAddingRecipients, setIsAddingRecipients] = useState<boolean>(false);
    const { dispatch } = MessagingState();
    const methods = useForm({ defaultValues: { recipients: [] } });
    const navigate = useNavigate();
    const { folderParam, pageNumber } = useParams();
    const location = useLocation();

    //used to prevent users from adding new users when thread is a reply to sender and shows correct date on title

    const cancelAddingRecipients = () => {
        setIsAddingRecipients(false);
    };

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>, id: number) => {
        if (ev.key === 'Enter') {
            setActiveDiscussionThread(id);
        }
    };

    const setActiveDiscussionThread = (id: number) => {
        navigate({
            pathname: `/${folderParam}/page/${pageNumber}`,
            search: location.search,
        });
        dispatch({
            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
            payload: { thread: null, loadingState: LoadingState.Done },
        });
        document.getElementById(`discussionThread${id}`)?.focus();
    };

    /**
     * Element that handles going back from the currently viewed thread.
     *
     * @returns JSX.Element
     */
    const getBackButtonElement = () => {
        return (
            <div
                tabIndex={0}
                className="btn btn-default icon-button"
                id="discussion-thread-back-button"
                aria-label={t('buttons.backToThreadList')}
                onClick={() => {
                    discussionThread && setActiveDiscussionThread(discussionThread.id);
                }}
                onKeyDown={e => {
                    discussionThread && handleKeyDown(e, discussionThread.id);
                }}>
                <Icon
                    name="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-arrow-left-circle"
                    size="sm"
                />
            </div>
        );
    };

    const getDiscussionThreadActions = () => {
        return discussionThread && <DiscussionThreadMenu activeDiscussionThread={discussionThread} />;
    };

    const ariaLabelForAddingRecipients = !isAddingRecipients
        ? t('buttons.quickAddRecipients')
        : t('buttons.cancelAddingRecipients');

    return (
        <div id="discussion-title-wrapper">
            <div className="row flex-nowrap">
                <div className="col-md-1">{getBackButtonElement()}</div>
                <div className="col-md-10">
                    <span
                        className="discussion-header"
                        tabIndex={-1}>
                        {discussionThread.name}
                    </span>
                </div>
                {getDiscussionThreadActions()}
            </div>
            <br />
            <div className="row">
                <div
                    id="discussion-title-author"
                    className="col-md">
                    {t('originalAuthor') + getUserDisplayName(discussionThread.author)}
                </div>
                <div
                    id="discussion-title-date"
                    className="col-md date">
                    {getSentAtTitle(discussionThread.threadCreationTime)}
                </div>
            </div>
            <div
                className="row"
                id="discussion-recipients-row">
                <div className="col">
                    <div
                        id="discussion-recipient-text-row"
                        className="col-md">
                        {t('to')}
                    </div>
                    <div className="recipient-form-container col-md-10">
                        <FormProvider {...methods}>
                            <QuickAddRecipients
                                discussionThread={discussionThread}
                                setIsAddingRecipients={setIsAddingRecipients}
                                recipients={discussionThread.recipients.filter(
                                    user => user.roleGuid !== discussionThread.author?.roleGuid
                                )}
                                displayLimit={3}
                                hiddenRecipientAmount={discussionThread.hiddenRecipientsCount}
                                placeholder={t('placeholders.recipients')}
                                isAddingRecipients={isAddingRecipients}
                            />
                        </FormProvider>
                        {discussionThread.canUserAddNewRecipients ? (
                            <div id="adding-recipients-wrapper">
                                <button
                                    id="quick-add-recipients-button"
                                    type="button"
                                    className={classNames('btn btn-default icon-button', {
                                        isAddingRecipients: isAddingRecipients,
                                    })}
                                    disabled={methods.formState.isSubmitting}
                                    aria-disabled={methods.formState.isSubmitting}
                                    aria-label={ariaLabelForAddingRecipients}
                                    onClick={() => {
                                        if (isAddingRecipients) {
                                            cancelAddingRecipients();
                                        } else {
                                            setIsAddingRecipients(true);
                                        }
                                    }}>
                                    {isAddingRecipients ? (
                                        <Icon
                                            name="cancel-circle"
                                            size="sm"
                                        />
                                    ) : (
                                        <Icon
                                            name="add"
                                            size="sm"
                                        />
                                    )}
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscussionTitle;
