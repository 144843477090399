import React from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

export type WilmaNavLinkProps = NavLinkProps & {
    disableLink?: boolean;
};

// This component is used to create a NavLink that is disabled when the current location is the same as the NavLink's 'to' prop.
const WilmaNavLink = (props: WilmaNavLinkProps & NavLinkProps & React.RefAttributes<HTMLAnchorElement>) => {
    const location = useLocation();

    const domProps = { ...props };
    delete domProps.disableLink;

    return location.pathname !== `/${props.to}` && !props.disableLink ? (
        <NavLink {...domProps}>{props.children}</NavLink>
    ) : (
        <span {...(domProps as React.RefAttributes<HTMLAnchorElement>)}>{props.children as React.ReactElement}</span>
    );
};

export default WilmaNavLink;
