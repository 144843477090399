import React, { ReactNode } from 'react';

interface TabItemProps {
    children: ReactNode;
    onClick: (event: React.SyntheticEvent) => void;
    isActive: boolean;
    tabIndex: number;
}

const TabItem = ({ children, onClick, isActive, tabIndex }: TabItemProps) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            onClick(event);
        }
    };

    return (
        <div
            onClick={onClick}
            tabIndex={tabIndex}
            onKeyDown={handleKeyDown}
            className={`tab-item ${isActive ? 'active' : ''}`}>
            {children}
        </div>
    );
};

export default TabItem;
