// Vendors
import { TFunction } from 'i18next';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import {
    GroupBase,
    components,
    ActionMeta,
    OptionProps,
    ControlProps,
    OptionsOrGroups,
    MultiValueProps,
} from 'react-select';
// Types
import { IUser } from '@appTypes/user';
// Components
import ShowMoreModal from './ShowMoreModal';
// Other
import { getStyles } from './WilmaAsyncSelectStyles';
import { getUserDisplayName } from '@common-utils';

export type StringOption = {
    allowMessaging: boolean;
    name: string;
    nameAbbreviation?: string;
    value: string;
    label: string;
    roleType: string;
    schoolNames: string[];
    extraInfo: string;
    isGroup: boolean;
};

export type RecipientOption = {
    value: string;
    label: string;
    roleType: string;
};

interface WilmaAsyncSelectProps {
    value?: StringOption[];
    disableAutofocus?: boolean;
    onBlur?: () => void;
    isSearchable?: boolean;
    ariaInvalid: 'true' | 'false';
    placeholder: string;
    loadingMessage: () => string;
    noOptionsMessage: () => string;
    loadOptions: (
        inputValue: string,
        options: OptionsOrGroups<StringOption, GroupBase<StringOption>>
    ) => Promise<{ options: StringOption[]; hasMore: boolean }>;
    onChange?: (option: readonly StringOption[], actionMeta: ActionMeta<StringOption>) => void;
    recipients?: IUser[];
    hiddenRecipientAmount?: number;
    hasErrors: boolean;
    ariaLabel?: string;
    displayLimit: number;
    hideRemoveButton?: boolean;
    hideMenu?: boolean;
    readonlyUsers?: string[];
}

const BoldedLabelWithSearch = (label: string, inputValue: string) => {
    if (inputValue == null || inputValue === '') {
        return label;
    }

    const textArray = label.split(RegExp(inputValue, 'ig'));
    const match = label.match(RegExp(inputValue, 'ig'));

    return (
        <span>
            {textArray.map((item, index) => (
                <React.Fragment key={label + index}>
                    {item}
                    {index !== textArray.length - 1 && match && <b>{match[index]}</b>}
                </React.Fragment>
            ))}
        </span>
    );
};

// Customized Option to include extra info about the recipient and supporting bolded search substrings to match Wilma search API's search terms
const Option = (props: OptionProps<StringOption>, t: TFunction<'translation', undefined>) => {
    const { data } = props;
    const nameAbbreviationPart =
        data.nameAbbreviation != null && data.nameAbbreviation !== '' ? ` (${data.nameAbbreviation})` : '';
    const optionalOldStyleGuardiansPrefix =
        data.roleType === 'OldStyleGuardian' ? `${t('roleType.OldStyleGuardian')}: ` : '';
    // If recipient was guardian, the search will also include name of the student, so bold that part of the search
    const extraInfoLabel =
        data.roleType === 'Guardian' ? BoldedLabelWithSearch(data.extraInfo, props.selectProps.inputValue) : data.extraInfo;
    const extraInfoPart = data.extraInfo != null && data.extraInfo !== '' ? <>, {extraInfoLabel}</> : '';
    const schoolNamePart = data.schoolNames.length > 0 ? `, ${data.schoolNames.join(', ')}` : '';

    return (
        <components.Option {...props}>
            <div className="select-option-first-row">
                <span className="capitalize">{optionalOldStyleGuardiansPrefix}</span>
                {BoldedLabelWithSearch(data.name, props.selectProps.inputValue)}
                {nameAbbreviationPart}
            </div>
            <div>
                <span className="capitalize">{t('roleType.' + data.roleType)}</span>
                {extraInfoPart}
                {schoolNamePart}
            </div>
        </components.Option>
    );
};

const formatOptionLabel = (option: StringOption) => {
    return <div>{getUserDisplayName({ roleGuid: option.value, label: option.label, roleType: option.roleType })}</div>;
};

interface LimitedControlProps extends ControlProps<StringOption, true, GroupBase<StringOption>> {
    hasValue: boolean;
    isSearchable?: boolean;
    getValue: () => readonly StringOption[];
}

const CustomControl = ({ getValue, isSearchable, ...props }: LimitedControlProps) => {
    const { children } = props;
    return (
        <components.Control
            {...props}
            className={classNames({ readonly: !isSearchable })}
            getValue={getValue}>
            <div className={classNames('grid', { readonly: !isSearchable })}>
                <components.Placeholder
                    getValue={getValue}
                    {...props}>
                    {props.selectProps.placeholder}
                </components.Placeholder>
                {children}
            </div>
        </components.Control>
    );
};

const WilmaAsyncSelect = ({
    value,
    disableAutofocus,
    onBlur,
    ariaInvalid,
    placeholder,
    isSearchable,
    loadingMessage,
    noOptionsMessage,
    loadOptions,
    onChange,
    recipients,
    hiddenRecipientAmount,
    hasErrors,
    ariaLabel,
    displayLimit,
    hideRemoveButton,
    hideMenu,
    readonlyUsers,
}: WilmaAsyncSelectProps) => {
    const { t } = useTranslation();
    const [showRecipientModal, setShowRecipientModal] = useState(false);
    const visibleRecipientLimit = displayLimit;

    const openRecipientModal = (e: React.MouseEvent<HTMLElement, MouseEvent> | React.TouchEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setShowRecipientModal(!showRecipientModal);
    };

    const messageRecipients = recipients ? recipients.map(recipient => recipient.roleGuid) : [];

    interface MoreSelectedBadgeProps {
        items: StringOption[];
    }

    const MoreSelectedBadge = ({ items }: MoreSelectedBadgeProps) => {
        const label = t('labels.showMoreCount', { count: items.length });
        return (
            <div
                role="button"
                onMouseDown={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onClick={e => openRecipientModal(e)}
                onTouchEnd={e => openRecipientModal(e)}
                className={'btn btn-link more-selected-badge'}>
                {label}
            </div>
        );
    };

    interface LimitedChipsContainerProps extends MultiValueProps<StringOption, true, GroupBase<StringOption>> {
        hasValue: boolean;
        index: number;
        getValue: () => readonly StringOption[];
        readonlyUsers?: string[];
    }

    const LimitedChipsContainer = ({ index, getValue, ...props }: LimitedChipsContainerProps) => {
        const allValues = getValue();
        const overflow = allValues.slice(visibleRecipientLimit);
        const componentsProp = {
            Remove:
                hideRemoveButton || (readonlyUsers && readonlyUsers.includes(props.data.value))
                    ? () => null
                    : components.MultiValueRemove,
            Container: components.MultiValueContainer,
            Label: components.MultiValueLabel,
        };
        return index < visibleRecipientLimit ? (
            <>
                <components.MultiValue
                    {...props}
                    index={index}
                    components={componentsProp}
                    getValue={getValue}
                />
                {hiddenRecipientAmount != null &&
                    hiddenRecipientAmount > 0 &&
                    index === allValues.length - 1 &&
                    t('hiddenRecipients', { count: hiddenRecipientAmount })}
            </>
        ) : index === visibleRecipientLimit || index === allValues.length ? (
            <MoreSelectedBadge items={overflow} />
        ) : null;
    };

    const componentsProp = {
        Control: CustomControl,
        MultiValue: LimitedChipsContainer,
        MenuList: hideMenu ? () => null : components.MenuList,
        Menu: hideMenu ? () => null : components.Menu,
        Placeholder: () => null,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: (props: OptionProps<StringOption>) => Option(props, t),
    };

    return (
        <>
            <AsyncPaginate
                autoFocus={disableAutofocus !== true}
                isMulti
                className={'wilma-async-select'}
                onBlur={onBlur}
                isSearchable={isSearchable}
                tabSelectsValue={true}
                aria-invalid={ariaInvalid}
                aria-label={ariaLabel}
                placeholder={placeholder}
                isClearable={false}
                formatOptionLabel={formatOptionLabel}
                backspaceRemovesValue={false}
                components={componentsProp}
                loadingMessage={loadingMessage}
                noOptionsMessage={noOptionsMessage}
                debounceTimeout={300}
                loadOptions={loadOptions}
                onChange={onChange}
                styles={getStyles(hasErrors)}
                isOptionDisabled={option => !option.allowMessaging}
                value={value}
                filterOption={option => !messageRecipients.includes(option.value)}
            />
            <ShowMoreModal
                shown={showRecipientModal}
                close={() => setShowRecipientModal(false)}
                recipientList={value || []}
                hideRemoveButton={hideRemoveButton}
                readonlyUsers={readonlyUsers}
            />
        </>
    );
};

export default WilmaAsyncSelect;
