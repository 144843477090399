import React from 'react';
import ReactPaginate from 'react-paginate';

interface IPaginateProps {
    currentPage: number;
    totalPages: number;
    handlePageClick: (event: { selected: number }) => void;
}

const Paginate = ({ currentPage, totalPages, handlePageClick }: IPaginateProps) => {
    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel=">>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={totalPages}
            previousLabel="<<"
            renderOnZeroPageCount={null}
            activeClassName="pagination-item active"
            containerClassName="pagination"
            nextClassName="pagination-item-next"
            previousClassName="pagination-item-prev"
            forcePage={currentPage - 1}
            pageClassName="pagination-item"
        />
    );
};

export default Paginate;
