// Vendors
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { NavigateFunction, useLocation, useNavigate, useParams, createSearchParams } from 'react-router-dom';
// Types
import { IFolder } from '../@types/folder';
import { ISearchPayload } from '../@types/search';
import { LoadingState } from '../@types/loadingstates';
// Context
import { useToast } from '../context/ToastContext';
import { ActionType } from '../context/ActionTypes';
import { MessagingState } from '../context/Context';
// Services
import DiscussionThreadService from '../services/DiscussionThreadService';
import FolderService, { FolderSelection } from '../services/FolderService';
// Hooks
import useDebounce from './hooks/useDebounce';
import useDetectOutsideClick from './hooks/useDetectOutsideClick';
import { useDiscussionThreads } from './hooks/useDiscussionThreads';

const SearchBar = () => {
    const { t } = useTranslation();
    const { createToast } = useToast();
    const folders = FolderService.getAll();
    const { getDiscussionThreads } = useDiscussionThreads();
    const { folderParam, pageNumber } = useParams();
    const {
        state: { searchFocus, searchPayload, discussionThreadsLoading },
        dispatch,
    } = MessagingState();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const firstMenuItemRef = useRef<HTMLLIElement>(null);

    useDetectOutsideClick(wrapperRef, () => setIsDropdownOpen(false));

    const navigate = useNavigate();
    const location = useLocation();

    const urlParams = new URLSearchParams(location.search);
    const searchTextFromUrl = urlParams.get('searchText');
    const folderSelectionFromUrl = urlParams.get('folderSelection');

    const ref = useRef<NavigateFunction>();
    ref.current = navigate;

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            const folderSelection = folderSelectionFromUrl
                ? parseInt(folderSelectionFromUrl)
                : searchPayload.folder.folderSelection;
            const folder = FolderService.getAll()[folderSelection];
            if (searchTextFromUrl || folderSelectionFromUrl) {
                dispatch({
                    type: ActionType.SET_SEARCH_PAYLOAD,
                    payload: {
                        ...searchPayload,
                        searchText: searchTextFromUrl || '',
                        folder: folder,
                    },
                });
                dispatch({
                    type: ActionType.SET_SEARCH_FOCUS,
                    payload: true,
                });
            }
        }
    }, [searchTextFromUrl, folderSelectionFromUrl, dispatch, searchPayload]);

    const { handleSubmit } = useForm({
        defaultValues: {
            searchText: searchPayload.searchText,
            folderSelection: FolderSelection.All,
        },
    });

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        dispatch({
            type: ActionType.SET_SEARCH_PAYLOAD,
            payload: { ...searchPayload, searchText: e.currentTarget.value },
        });
    };

    const handleKeyDownButton = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (!isDropdownOpen) {
                setIsDropdownOpen(true);
                setSelectedItemIndex(0);
            } else if (firstMenuItemRef.current) {
                firstMenuItemRef.current.focus();
                setSelectedItemIndex(0);
            }
        } else if (e.key === 'Enter') {
            toggleDropdownOpen();
        }
    };

    const handleKeyDownMenuItem = (e: React.KeyboardEvent<HTMLLIElement>, index: number) => {
        if (e.key === 'ArrowDown' && index < folders.length - 1) {
            e.preventDefault();
            setSelectedItemIndex(index + 1);
            const nextMenuItem = wrapperRef.current?.querySelector(`[data-index="${index + 1}"]`) as HTMLLIElement | null;
            if (nextMenuItem) {
                nextMenuItem.focus();
            }
        } else if (e.key === 'ArrowUp' && index > 0) {
            e.preventDefault();
            setSelectedItemIndex(index - 1);
            const prevMenuItem = wrapperRef.current?.querySelector(`[data-index="${index - 1}"]`) as HTMLLIElement | null;
            if (prevMenuItem) {
                prevMenuItem.focus();
            }
        } else if (e.key === 'Enter') {
            handleClickDropdownItem(e, folders[index]);
        }
    };

    const handleClickDropdownItem = (e: React.FormEvent, folder: IFolder) => {
        //prevents keydown event bubbling up to search input and initiating search
        e.preventDefault();
        dispatch({
            type: ActionType.SET_SEARCH_PAYLOAD,
            payload: { ...searchPayload, folder: folder },
        });
        setIsDropdownOpen(false);
        document.getElementById('search-dropdown-button')?.focus();
    };

    const toggleDropdownOpen = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setSelectedItemIndex(-1);
    };

    const commitSearch = async () => {
        const trimmedSearchText = searchPayload.searchText.trim();
        if (trimmedSearchText === '') {
            const folder = FolderService.getAll().find(folder => folder.path === folderParam) || FolderService.getAll()[0];
            dispatch({
                type: ActionType.SET_SEARCH_PAYLOAD,
                payload: {
                    ...searchPayload,
                    folder: folder,
                    searchText: trimmedSearchText,
                },
            });
            dispatch({
                type: ActionType.SET_SEARCH_FOCUS,
                payload: false,
            });
            dispatch({
                type: ActionType.SET_SEARCH_DISCUSSIONTHREADS,
                payload: [],
            });
            getDiscussionThreads(folder);
            navigate(location.pathname);
            return;
        }
        amplitude.track('search_submitted', {
            search_text: searchPayload.searchText,
        });
        dispatch({
            type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
            payload: LoadingState.Loading,
        });

        const searchParams: ISearchPayload = {
            searchText: searchPayload.searchText,
            folderSelection: searchPayload.folder.folderSelection,
        };

        amplitude.track('search_submitted', {
            search_text: searchParams.searchText,
        });
        try {
            const { paginationData, discussionThreads } = await DiscussionThreadService.search(searchParams);
            const { currentPage, totalPages } = paginationData;
            dispatch({
                type: ActionType.SET_SEARCH_DISCUSSIONTHREADS,
                payload: discussionThreads,
            });
            dispatch({
                type: ActionType.SET_PAGINATION_DATA,
                payload: {
                    currentPage,
                    totalPages,
                },
            });
            const baseUrl = `/${folderParam}/page/${pageNumber}`;
            const queryString = `search&${createSearchParams({
                searchText: searchParams.searchText,
                folderSelection: searchParams.folderSelection.toString(),
                page: currentPage.toString(),
            }).toString()}`;

            const url = location.pathname.includes('compose') ? `${baseUrl}/compose` : `${baseUrl}`;
            // https://github.com/remix-run/react-router/issues/11240
            // Need to use ref, since async call will not cause re-render for navigation
            ref.current && ref.current({ pathname: url, search: queryString });
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Done,
            });
        } catch (error) {
            createToast({
                title: t('errors.searchError'),
                toastType: 'danger',
            });
            dispatch({
                type: ActionType.SET_DISCUSSIONTHREADS_LOADING,
                payload: LoadingState.Error,
            });
        }
    };

    const debounceCommitSearch = useDebounce(commitSearch, 500);
    const onSubmit = async () => {
        if (!searchPayload.searchText) {
            return;
        } else if (discussionThreadsLoading !== LoadingState.Loading) {
            await commitSearch();
        } else {
            debounceCommitSearch();
        }
    };

    const setSearchFocus = (isFocus: boolean) => {
        dispatch({
            type: ActionType.SET_SEARCH_FOCUS,
            payload: isFocus,
        });
    };

    const onInputFocus = () => {
        setSearchFocus(true);
    };

    const focusInCurrentTarget = ({ relatedTarget, currentTarget }: React.FocusEvent<HTMLInputElement>) => {
        if (relatedTarget === null) return false;

        let node = relatedTarget.parentNode;

        while (node !== null) {
            if (node === currentTarget) return true;
            node = node.parentNode;
        }
        return false;
    };

    const onContainerBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!focusInCurrentTarget(e) && !searchPayload.searchText && !isDropdownOpen) {
            setSearchFocus(false);
            if (searchTextFromUrl) {
                dispatch({
                    type: ActionType.SET_SEARCH_PAYLOAD,
                    payload: { ...searchPayload, searchText: searchTextFromUrl },
                });
            } else {
                setSearchFocus(false);
            }
        }
    };

    return (
        <div
            onBlur={onContainerBlur}
            className={classNames('middle-row', searchFocus ? 'col-md-9' : 'col-md-3')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group row no-gutters">
                    {searchFocus && (
                        <div className="col-md-auto">
                            <div
                                ref={wrapperRef}
                                className={isDropdownOpen ? 'dropdown open' : 'dropdown'}>
                                <button
                                    ref={buttonRef}
                                    type="button"
                                    className="btn btn-primary bg-secondary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-controls="folder-menu"
                                    aria-expanded={isDropdownOpen ? 'true' : 'false'}
                                    id="search-dropdown-button"
                                    onKeyDown={e => handleKeyDownButton(e)}
                                    onClick={toggleDropdownOpen}>
                                    {t(searchPayload.folder.name)}
                                </button>
                                <ul
                                    id="folder-menu"
                                    className="dropdown-menu"
                                    role="menu"
                                    tabIndex={0}
                                    aria-hidden={!isDropdownOpen}
                                    aria-expanded={isDropdownOpen}
                                    aria-labelledby="search-dropdown-button">
                                    {folders.map((folder, index) => {
                                        // TODO: Remove isDisabled when all folders are implemented
                                        const isDisabled = folder.folderSelection === FolderSelection.Disabled;
                                        const isSelected = index === selectedItemIndex;
                                        return (
                                            <li
                                                className={classNames({
                                                    'dropdown-item': true,
                                                    disabled: isDisabled,
                                                    selected: isSelected,
                                                })}
                                                role="menuitem"
                                                tabIndex={-1}
                                                data-index={index}
                                                key={`searchFolder${folder.id}`}
                                                onKeyDown={e => handleKeyDownMenuItem(e, index)}
                                                ref={index === 0 ? firstMenuItemRef : null}
                                                onClick={e => handleClickDropdownItem(e, folder)}>
                                                <a href="#">{t(folder.name)}</a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                    <div className={searchFocus ? 'col-md-5' : 'col-md-12'}>
                        <div className="search-group search-group-primary">
                            <input
                                id="search-bar"
                                onFocus={onInputFocus}
                                className="form-control"
                                type="search"
                                role="search"
                                onChange={handleChange}
                                value={searchPayload.searchText}
                                placeholder={t('placeholders.search')}
                                aria-label={t('searchInput')}
                            />
                            {searchFocus && (
                                <button
                                    className="btn search-icon"
                                    id="search-button"
                                    name="searchBtn"
                                    aria-label={t('labels.searchButton')}
                                    title={t('search')}
                                    tabIndex={0}
                                    type="submit"
                                />
                            )}
                            <span className="search-icon" />
                            <span className="clear-search" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SearchBar;
