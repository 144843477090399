// Vendors
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ListContainer, ListGroup } from '@vismaux/react-vud';
// Context
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Types
import { LoadingState } from '@appTypes/loadingstates';
import { IGradebook, IPeriodGroup } from '@appTypes/recipients';
// Services
import RecipientService from '@services/RecipientService';
// Components
import { RecipientSelectionTypes } from './RecipientsPersonList';

interface IGradebookProps {
    gradeBooks: IGradebook;
    gradebookHeader: string;
}

const Gradebooks = ({ gradeBooks, gradebookHeader }: IGradebookProps) => {
    const {
        state: { activeButtonId },
        dispatch,
    } = MessagingState();
    const { t } = useTranslation();

    const setActiveButtonId = (id: string) => {
        dispatch({
            type: ActionType.SET_ACTIVE_BUTTON_ID,
            payload: id,
        });
    };

    const onClickGradeBook = async (event: React.MouseEvent<HTMLButtonElement>, groupId: string, currentClass: string) => {
        event.preventDefault();
        if (groupId === activeButtonId) {
            return;
        }
        setActiveButtonId(groupId);
        dispatch({
            type: ActionType.SET_RECIPIENTLIST_VIEW,
            payload: {
                showRecipientList: RecipientSelectionTypes.Loading,
                records: [],
            },
        });
        try {
            const response = await RecipientService.getRecipientsByGroup(groupId);
            if (RecipientService.loadingState !== LoadingState.Loading) {
                dispatch({
                    type: ActionType.SET_RECIPIENTLIST_VIEW,
                    payload: {
                        showRecipientList: RecipientSelectionTypes.Students,
                        records: response.studentRecords,
                        currentClass: currentClass,
                    },
                });
            }
        } catch {
            dispatch({
                type: ActionType.SET_RECIPIENTLIST_VIEW,
                payload: {
                    showRecipientList: RecipientSelectionTypes.Error,
                    records: [],
                },
            });
        }
    };

    const getGradebookCaption = (periodGroup: IPeriodGroup) => {
        return (
            periodGroup.gradebookName ||
            periodGroup.fullCaption ||
            t('selectRecipients.unknownGradebook', { id: periodGroup.id })
        );
    };

    return (
        <>
            {gradeBooks.periodGroups.length > 0 && (
                <ListContainer>
                    <ListGroup title={gradebookHeader}>
                        {gradeBooks.periodGroups.map((periodGroup: IPeriodGroup) => {
                            return (
                                <button
                                    key={periodGroup.id}
                                    className={classNames('recipient-list-group-item-gradebook', {
                                        active: periodGroup.id === activeButtonId,
                                    })}
                                    onClick={e => onClickGradeBook(e, periodGroup.id, getGradebookCaption(periodGroup))}>
                                    <div className="recipient-list-text-gradebook">{getGradebookCaption(periodGroup)}</div>
                                </button>
                            );
                        })}
                    </ListGroup>
                </ListContainer>
            )}
        </>
    );
};

export default Gradebooks;
