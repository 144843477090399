// Vendors
import React from 'react';
import FocusTrap from 'focus-trap-react';
import { useTranslation } from 'react-i18next';

type Props = {
    isShowing: boolean;
    close: () => void;
};

const ShowInfoModal = ({ isShowing, close }: Props) => {
    const { t } = useTranslation();

    const closeModal = () => {
        close();
    };

    return isShowing ? (
        <FocusTrap>
            <div
                className="modal-backdrop"
                onClick={closeModal}>
                <div
                    className="info-modal-content container-fluid"
                    onClick={e => {
                        e.stopPropagation();
                    }}>
                    <div className="row info-row">
                        <h1>{t('infoModal.headerSeeNames')}</h1>
                        <p>{t('infoModal.bodySeeNames')}</p>
                        <h1>{t('infoModal.headerSeeResponses')}</h1>
                        <p>{t('infoModal.bodySeeResponses')}</p>
                        <p>{t('infoModal.bodySeeResponsesExtra')}</p>
                        <h2>{t('infoModal.headerWhenToUse')}</h2>
                        <p>{t('infoModal.bodyWhenToUse')}</p>
                        <h2>{t('infoModal.headerWhenNotToUse')}</h2>
                        <p>{t('infoModal.bodyWhenNotToUse')}</p>
                    </div>
                    <div className="row modal-close">
                        <button
                            className="btn btn-primary bg-secondary action-button"
                            onKeyDown={e => e.key === 'Enter' && closeModal()}
                            onClick={closeModal}>
                            {t('buttons.close')}
                        </button>
                    </div>
                </div>
            </div>
        </FocusTrap>
    ) : null;
};

export default ShowInfoModal;
