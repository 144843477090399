// Vendors
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// Context
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Types
import { LoadingState } from '@appTypes/loadingstates';
import { IIndexRecord, IRecord } from '@appTypes/recipients';
// Services
import RecipientService from '@services/RecipientService';
// Other
import { RecipientSelectionTypes } from './RecipientsPersonList';

interface IIndexRecordsCategoryButtonProps {
    indexRecord: IIndexRecord;
    recipientType: RecipientSelectionTypes;
    serviceMethod: (id: string) => Promise<IRecord[]>;
}

const IndexRecordsCategoryButton = ({ indexRecord, recipientType, serviceMethod }: IIndexRecordsCategoryButtonProps) => {
    const { t } = useTranslation();
    const {
        state: { activeButtonId },
        dispatch,
    } = MessagingState();

    const setActiveButtonId = (id: string) => {
        dispatch({
            type: ActionType.SET_ACTIVE_BUTTON_ID,
            payload: id,
        });
    };

    const onClickCategory = async (
        event: React.MouseEvent<HTMLButtonElement>,
        schoolId: string,
        currentSchoolName: string,
        callFunction: (id: string) => Promise<IRecord[]>
    ) => {
        event.preventDefault();
        if (schoolId + recipientType === activeButtonId) {
            return;
        }
        setActiveButtonId(indexRecord.id + recipientType);
        dispatch({
            type: ActionType.SET_RECIPIENTLIST_VIEW,
            payload: {
                showRecipientList: RecipientSelectionTypes.Loading,
                records: [],
            },
        });
        try {
            const records = await callFunction(schoolId);
            if (RecipientService.loadingState !== LoadingState.Loading) {
                dispatch({
                    type: ActionType.SET_RECIPIENTLIST_VIEW,
                    payload: {
                        showRecipientList: recipientType,
                        records: records,
                        currentSchool: currentSchoolName,
                    },
                });
            }
        } catch {
            dispatch({
                type: ActionType.SET_RECIPIENTLIST_VIEW,
                payload: {
                    showRecipientList: RecipientSelectionTypes.Error,
                    records: [],
                },
            });
        }
    };

    return (
        <button
            className={classNames('recipient-list-group-item-1', {
                active: indexRecord.id + recipientType === activeButtonId,
            })}
            onClick={e => {
                onClickCategory(e, indexRecord.id, indexRecord.caption, serviceMethod);
            }}>
            <div className="recipient-list-text">{t(`selectRecipients.${recipientType}`)}</div>
        </button>
    );
};

export default IndexRecordsCategoryButton;
