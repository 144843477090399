import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
import DiscussionThreadService from '@services/DiscussionThreadService';
import { LoadingState } from '@appTypes/loadingstates';
import { useCallback } from 'react';

export const useUpdateInboxUnreadCount = () => {
    const { dispatch } = MessagingState();

    const updateInboxUnreadCount = useCallback(async () => {
        try {
            dispatch({
                type: ActionType.SET_UNREAD_INBOX_COUNT,
                payload: {
                    count: 0,
                    loadingState: LoadingState.Loading,
                },
            });
            const response = await DiscussionThreadService.InboxUnreadCount();
            dispatch({
                type: ActionType.SET_UNREAD_INBOX_COUNT,
                payload: {
                    count: response.unreadCount,
                    loadingState: LoadingState.Done,
                },
            });
        } catch (err) {
            dispatch({
                type: ActionType.SET_UNREAD_INBOX_COUNT,
                payload: {
                    count: 0,
                    loadingState: LoadingState.Error,
                },
            });
            console.error(err);
        }
    }, [dispatch]);

    return { updateInboxUnreadCount };
};
