// Vendors
import classNames from 'classnames';
import { Guid } from 'typescript-guid';
import { Icon } from '@vismaux/react-vud';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import * as amplitude from '@amplitude/analytics-browser';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate, useParams, useLocation } from 'react-router-dom';
// Types
import { IUser } from '@appTypes/user';
import { LoadingState } from '@appTypes/loadingstates';
import { IRecipientId, IRecord } from '@appTypes/recipients';
import { IDiscussionThreadSelectPayload, IDiscussionThreadCreatePayload } from '@appTypes/discussionthread';
// Context
import { useToast } from '@context/ToastContext';
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Services
import RecipientService from '@services/RecipientService';
// Components
import ShowInfoModal from './ShowInfoModal';
import useModal from '@components/hooks/useModal';
import MessageModal from '@components/Modals/MessageModal';
import useRecipientModal from '@components/hooks/useRecipientModal';
import DiscussionThreadService from '@services/DiscussionThreadService';
import SelectRecipientsModal from '@components/SelectRecipients/SelectRecipientsModal';
import SelectRecipientsWrapper from '@components/SelectRecipients/SelectRecipientsWrapper';
import WilmaAsyncSelect, { StringOption } from '@components/WilmaAsyncSelect/WilmaAsyncSelect';

const NewDiscussionThread = () => {
    const { t } = useTranslation();
    const {
        state: { message, selectedRecipients, modalRecipients, paginationData },
        dispatch,
    } = MessagingState();
    const [displayLimit, setDisplayLimit] = useState(5);
    const colRef = useRef<HTMLDivElement>(null);
    const { createToast } = useToast();
    const modal = useModal();
    const recipientModal = useRecipientModal();
    const [modalConfirmed, setModalConfirmed] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const { folderParam, pageNumber } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const ref = useRef<NavigateFunction>();
    ref.current = navigate;

    const validationErrors = {
        requiredError: {
            recipientError: t('errors.requiredError.recipientError'),
            messageError: t('errors.requiredError.messageError'),
        },
        lengthError: {
            recipientError: t('errors.lengthError.recipientError'),
        },
    };
    const defaultAmplitudeProps = {
        guid_id: Guid.create().toString(),
    };
    const [amplitudeProps] = useState(defaultAmplitudeProps);

    const setMessage = useCallback(
        (message: IDiscussionThreadSelectPayload, isIntialLoad?: true) => {
            const searchParams = new URLSearchParams(location.search);
            const showRecipients = searchParams.get('showrecipients') === '1';
            const showReplies = showRecipients ? searchParams.get('collatedreplies') === '1' : false;
            dispatch({
                type: ActionType.SET_MESSAGE,
                payload: {
                    recipients: message.recipients,
                    title: message.title,
                    message: message.message,
                    seeNames: isIntialLoad ? showRecipients : message.seeNames,
                    seeResponses: isIntialLoad ? showReplies : message.seeResponses,
                },
            });
        },
        [dispatch, location.search]
    );

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            recipients: message.recipients,
            title: message.title,
            message: message.message,
        },
    });

    useEffect(() => {
        const users = selectedRecipients.options.map(option => {
            return {
                ...option,
                roleGuid: option.value,
            };
        });
        setValue('recipients', users);
    }, [selectedRecipients.options, setValue]);

    const onCancel = () => {
        amplitude.track('new_message_cancelled', amplitudeProps);
        dispatch({
            type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
            payload: { thread: null, loadingState: LoadingState.Done },
        });
        dispatch({
            type: ActionType.SET_MESSAGE,
            payload: {
                title: '',
                recipients: [],
                message: '',
                seeNames: false,
                seeResponses: false,
            },
        });
        dispatch({
            type: ActionType.SET_SELECTED_RECIPIENTS,
            payload: { options: [] },
        });
        navigate({
            pathname: `/${folderParam}/page/${pageNumber}`,
            search: location.search,
        });
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        setMessage({ ...message, [name]: value });
    };

    const onOtherOptionsCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.checked;
        const newMessage = { ...message, [name]: value };
        if (name === 'seeNames' && value === false) {
            newMessage.seeResponses = false;
        }
        setMessage(newMessage);
    };

    async function createDiscussionThread() {
        amplitude.track('new_message_form_submitted', amplitudeProps);

        const discussionThread: IDiscussionThreadCreatePayload = {
            message: message.message,
            title: message.title,
            recipientGuids: message.recipients.map(recipient => recipient.roleGuid),
            seeNames: message.seeNames,
            seeResponses: message.seeResponses,
        };

        try {
            const createdThread = await DiscussionThreadService.create(discussionThread);
            setMessage(message);
            if (folderParam === 'sent') {
                const pagedResponsePayload = await DiscussionThreadService.getSent({
                    pageNumber: paginationData.currentPage,
                    pageSize: 10,
                });
                dispatch({
                    type: ActionType.SET_DISCUSSIONTHREADS,
                    payload: pagedResponsePayload.discussionThreads,
                });
                dispatch({
                    type: ActionType.SET_PAGINATION_DATA,
                    payload: {
                        currentPage: pagedResponsePayload.paginationData.currentPage,
                        totalPages: pagedResponsePayload.paginationData.totalPages,
                    },
                });
            }
            dispatch({
                type: ActionType.SET_MESSAGE,
                payload: {
                    title: '',
                    recipients: [],
                    message: '',
                    seeNames: false,
                    seeResponses: false,
                },
            });
            dispatch({
                type: ActionType.SET_SELECTED_RECIPIENTS,
                payload: { options: [] },
            });
            dispatch({
                type: ActionType.SET_LAST_MESSAGE_SENT,
                payload: { threadId: createdThread.data.id, canCancelThread: createdThread.data.canCancelThread },
            });

            // https://github.com/remix-run/react-router/issues/11240
            // Need to use ref, since async call on sent-folder will not cause re-render for navigation
            ref.current && ref.current(`/${folderParam}/page/${pageNumber}/messagesent${location.search}`);
        } catch (error) {
            console.error(error);
            createToast({
                title: t('errors.messageSendingError'),
                toastType: 'danger',
            });
            amplitude.track('error_after_form_submitted', amplitudeProps);
        }
    }

    const promiseOptions = async (inputValue: string) => {
        try {
            const response = await RecipientService.searchRecipients(inputValue);
            const records = response.data.allRecords.map(record => {
                const option: StringOption = {
                    label: record.label,
                    value: record.roleGuid,
                    name: record.name,
                    nameAbbreviation: record.nameAbbreviation,
                    allowMessaging: record.allowSendingMessage,
                    roleType: record.roleType,
                    schoolNames: record.schoolNames,
                    extraInfo: record.extraInfo,
                    isGroup: false,
                };
                return option;
            });
            // hasMore is false since API doesn't support pagination
            return { options: records, hasMore: false };
        } catch (error) {
            createToast({
                title: t('errors.recipientLoadingFailed'),
                toastType: 'danger',
            });
            return { options: [], hasMore: false };
        }
    };

    const trackFocus = (event: React.FocusEvent<Element>) => {
        amplitude.track(event.target.id + '_focused', amplitudeProps);
    };

    const trackBlur = (event: React.FocusEvent<Element>) => {
        amplitude.track(event.target.id + '_unfocused', {
            ...amplitudeProps,
            new_message_length: message.message.length,
            new_message_title_length: message.title.length,
        });
    };

    const handleChange = (
        controllerOnChange: (event: IUser[] | ChangeEvent<Element>) => void,
        options: readonly StringOption[]
    ) => {
        // single users in select-component
        const users = options
            .filter(u => !u.isGroup)
            .map(u => {
                const recipient: IUser = { label: u.label, roleGuid: u.value, roleType: u.roleType };
                return recipient;
            });

        // group users in select-component
        const optionGroupLabels = options.filter(option => option.isGroup).map(option => option.value || '');

        const filteredGroupUsers = modalRecipients.modalRecipientOptions.filter(option =>
            optionGroupLabels.includes(option.value)
        );
        // map single recipients from group to be an array of single IUser
        const remainingGroupUsers: IUser[] = filteredGroupUsers.flatMap(option => {
            const group = option.value;
            return option.options.map(o => ({
                label: o.label,
                roleGuid: o.value,
                roleType: o.roleType,
                groupLabel: group,
            }));
        });

        setMessage({ ...message, recipients: [...users, ...remainingGroupUsers] });
        amplitude.track('new_message_thread_recipient_changed', {
            ...amplitudeProps,
            recipient_count: users.length,
        });
        controllerOnChange(users);

        dispatch({
            type: ActionType.SET_SELECTED_RECIPIENTS,
            payload: { options: options.map(o => o) },
        });
    };

    const toggleRecipients = async () => {
        if (!recipientModal.isOpen) {
            dispatch({
                type: ActionType.SET_GENERAL_RECIPIENT_DATA,
                payload: { loadingState: LoadingState.Loading, indexRecords: [] },
            });
            try {
                const response = await RecipientService.getRecipients();
                dispatch({
                    type: ActionType.SET_GENERAL_RECIPIENT_DATA,
                    payload: { ...response, loadingState: LoadingState.Done },
                });
                const stringOptionsToModalRecipients = selectedRecipients.options.map(option => {
                    const groupModalRecipient = modalRecipients.modalRecipientOptions.find(o => o.value === option.value);
                    const modalRecipient = {
                        value: option.value,
                        options: groupModalRecipient ? groupModalRecipient.options : [option],
                        isGroup: option.isGroup,
                    };
                    return modalRecipient;
                });
                dispatch({
                    type: ActionType.SET_MODAL_RECIPIENTS,
                    payload: { modalRecipientOptions: stringOptionsToModalRecipients },
                });
            } catch (e) {
                console.error(e);
                dispatch({
                    type: ActionType.SET_GENERAL_RECIPIENT_DATA,
                    payload: { loadingState: LoadingState.Error, indexRecords: [] },
                });
            }
        }
        recipientModal.toggleRecipients();
    };

    useEffect(() => {
        amplitude.track('new_message_view_added', amplitudeProps);
    }, [amplitudeProps]);

    useEffect(() => {
        const updateDisplayLimit = () => {
            if (colRef.current) {
                const width = colRef.current.offsetWidth;
                setDisplayLimit(width < 600 ? 3 : 5);
            }
        };
        // Initial calculation on mount
        updateDisplayLimit();

        // Recalculate on window resize
        window.addEventListener('resize', updateDisplayLimit);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateDisplayLimit);
        };
    }, []);

    useEffect(() => {
        const searchRecordIdAndRoleType = async (recipientIds: IRecipientId[]): Promise<IRecord[]> => {
            // Validate recipientIds
            recipientIds.forEach(id => {
                if (!id.roleType || !id.cardNumber) {
                    throw new Error('Recipient id is missing roleType or cardNumber');
                }
            });

            try {
                const response = await RecipientService.postRecipientsInfo(recipientIds);
                return response.data; // Extract the data property
            } catch (error) {
                console.error(error);
                createToast({
                    title: t('errors.recipientLoadingFailed'),
                    toastType: 'danger',
                });
                return []; // Return an empty array in case of error
            }
        };

        async function fetchData() {
            const searchParams = new URLSearchParams(location.search);
            // Search for all possible recipient keys
            const searchParamsKeys = [
                'r_teacher',
                'r_student',
                'r_personnel',
                'r_guardian',
                'r_workplaceinstructor',
                'r_class',
                'r_group',
                'r_classguardian',
                'r_schoolteacher',
                'r_studentsearch',
                'r_guardiansearch',
                'r_groupguardian',
                'r_teachersearch',
                'r_schoolpersonnel',
                'r_personnelsearch',
                'r_ownteachers',
                'r_classteachers',
                'r_studentteachers',
                'r_groupteachers',
                'r_schoolworkplaceinstructors',
                'r_workplaceinstructorssearch',
                'r_schooltrainingcoordinators',
                'r_trainingcoordinatorssearch',
            ];

            const recipientIds: IRecipientId[] = searchParamsKeys
                .flatMap(roleType =>
                    searchParams.getAll(roleType).flatMap(cardNumber => ({
                        roleType,
                        cardNumber,
                    }))
                )
                .filter(id => typeof id.cardNumber !== 'undefined' && id.cardNumber);

            if (recipientIds.length === 0) {
                setMessage({ ...message }, true);
                redirectToComposePage();
                return;
            }

            const recipientRecords = await searchRecordIdAndRoleType(recipientIds);

            const recordIUsers: IUser[] = recipientRecords.map(record => {
                return { label: record.label, roleGuid: record.roleGuid, roleType: record.roleType };
            });

            const recordStringOptions: StringOption[] = recipientRecords.map(record => {
                return {
                    name: record.name,
                    label: record.label,
                    value: record.roleGuid,
                    roleType: record.roleType,
                    allowMessaging: record.allowSendingMessage,
                    schoolNames: record.schoolNames,
                    extraInfo: record.extraInfo,
                    isGroup: false,
                };
            });
            setMessage({ ...message, recipients: recordIUsers }, true);
            dispatch({
                type: ActionType.SET_SELECTED_RECIPIENTS,
                payload: { options: recordStringOptions },
            });
            redirectToComposePage();
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Run only once on mount

    const redirectToComposePage = () => {
        if (!location.search.includes('search')) {
            navigate('/received/page/1/compose', { replace: true });
        }
    };

    const onSubmit = () => {
        if (message.title.trim().length === 0) {
            modal.toggle();
        } else {
            createDiscussionThread();
        }
    };

    const controllerRules = {
        required: validationErrors.requiredError.recipientError,
    };

    return (
        <div className="container-fluid h-100">
            <form
                className="d-flex flex-column h-100"
                onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div
                        id="new-message-recipients-header-row-label"
                        className="col-lg-2">
                        <button
                            type="button"
                            className="btn btn-primary bg-secondary action-button"
                            id="select-recipients-button"
                            onClick={toggleRecipients}>
                            {t('selectRecipients.title')}
                        </button>
                    </div>
                    <div
                        ref={colRef}
                        className="col-lg-10 col-md-12">
                        <Controller
                            name="recipients"
                            control={control}
                            rules={controllerRules}
                            render={({ field: { onChange, onBlur } }) => (
                                <>
                                    <WilmaAsyncSelect
                                        value={selectedRecipients.options}
                                        onBlur={onBlur}
                                        ariaInvalid={errors.recipients ? 'true' : 'false'}
                                        placeholder={t('placeholders.recipients')}
                                        loadingMessage={() => t('loading')}
                                        loadOptions={promiseOptions}
                                        noOptionsMessage={() => t('noRecipientsFound')}
                                        onChange={e => handleChange(onChange, e)}
                                        recipients={message.recipients}
                                        hasErrors={errors.recipients ? true : false}
                                        ariaLabel={t('inputs.recipientsList')}
                                        displayLimit={displayLimit}
                                    />
                                    {<p className="error-text">{errors.recipients?.message}</p>}
                                </>
                            )}
                        />
                        <div className="switch">
                            <input
                                type="checkbox"
                                id="check-see-other-names"
                                name="seeNames"
                                onChange={onOtherOptionsCheckboxChange}
                                checked={message.seeNames}
                            />
                            <label
                                htmlFor="check-see-other-names"
                                className="togglemark">
                                {t('inputs.seeOtherNames')}
                            </label>
                        </div>
                        <div className="switch">
                            <input
                                type="checkbox"
                                id="check-see-other-responses"
                                name="seeResponses"
                                disabled={!message.seeNames}
                                onChange={onOtherOptionsCheckboxChange}
                                checked={message.seeResponses}
                            />
                            <label
                                htmlFor="check-see-other-responses"
                                className="togglemark">
                                {t('inputs.seeOtherResponses')}
                            </label>
                        </div>
                        <div
                            id="show-other-settings-info"
                            tabIndex={0}
                            onKeyDown={e => e.key === 'Enter' && setInfoModalOpen(true)}
                            onClick={() => setInfoModalOpen(true)}>
                            {t('inputs.otherSettingsInfo')}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <label
                        htmlFor="title"
                        className="col-lg-2">
                        {t('headers.title')}:
                    </label>
                    <div className="col-lg-10 col-md-12">
                        <input
                            {...register('title', {
                                onBlur: e => {
                                    trackBlur(e);
                                },
                            })}
                            type="text"
                            id="title_field"
                            aria-invalid={errors.title ? 'true' : 'false'}
                            className={errors.title ? 'has-error' : ''}
                            value={message.title}
                            onChange={onChange}
                            onFocus={e => trackFocus(e)}
                            placeholder={t('placeholders.title')}
                        />
                    </div>
                </div>
                <div
                    className="row justify-content-center flex-grow-1"
                    id="discussion-thread-message-textarea">
                    <label
                        htmlFor="message"
                        aria-label={t('inputs.messageTextarea')}
                    />
                    <div className="col-md-12">
                        <textarea
                            {...register('message', {
                                required: validationErrors.requiredError.messageError,
                                onBlur: e => {
                                    trackBlur(e);
                                },
                            })}
                            aria-invalid={errors.message ? 'true' : 'false'}
                            aria-label={t('inputs.messageTextarea')}
                            className={classNames('h-100 resize-none', 'message-full-height', {
                                'has-error': errors.message,
                            })}
                            id="message_field"
                            value={message.message}
                            onChange={onChange}
                            onFocus={e => trackFocus(e)}
                            placeholder={t('placeholders.message')}
                        />
                        {<p className="error-text">{errors.message?.message}</p>}
                    </div>
                </div>
                <hr />
                <div>
                    <div className="float-right">
                        <button
                            type="button"
                            disabled={isSubmitting || modal.isOpen || modalConfirmed}
                            className="btn bg-transparent action-button"
                            onClick={onCancel}
                            aria-disabled={isSubmitting || modal.isOpen || modalConfirmed}>
                            {t('cancel')}
                        </button>
                        <button
                            type="submit"
                            disabled={isSubmitting || modal.isOpen || modalConfirmed}
                            className="btn btn-primary bg-secondary action-button"
                            aria-disabled={isSubmitting || modal.isOpen || modalConfirmed}>
                            <Icon
                                name="paperplane"
                                size="sm"
                            />
                            {t('submitMessage')}
                        </button>
                        {(isSubmitting || modal.isOpen || modalConfirmed) && (
                            <span className="spinner spinner-sm spinner-default-blue"></span>
                        )}
                    </div>
                </div>
            </form>
            <MessageModal
                isShowing={modal.isOpen}
                toggle={modal.toggle}
                title={t('prompts.confirm.noTitleNewMessage')}
                bodyContent={<div />}
                handleConfirm={() => {
                    setModalConfirmed(true);
                    createDiscussionThread();
                }}
            />
            <SelectRecipientsModal
                isShowing={recipientModal.isOpen}
                toggle={recipientModal.toggleRecipients}
                bodyContent={<SelectRecipientsWrapper />}
            />
            <ShowInfoModal
                isShowing={infoModalOpen}
                close={() => setInfoModalOpen(false)}
            />
        </div>
    );
};

export default NewDiscussionThread;
