// Vendors
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';

const MessageCanceled = () => {
    const { t } = useTranslation();
    const messageSentRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (messageSentRef.current) {
            messageSentRef.current.focus();
        }
    }, []);

    return (
        <>
            <div className="sent-message-container">
                <span
                    aria-hidden="true"
                    className="vismaicon visma-pictogram visma-pictogram-happy-file undo-icon"
                />
                <p
                    className="sent-message-text"
                    ref={messageSentRef}
                    tabIndex={-1}>
                    {t('messageCanceled')}
                </p>
            </div>
        </>
    );
};

export default MessageCanceled;
