// Vendors
import React from 'react';
import classNames from 'classnames';
import { Checkbox } from '@vismaux/react-vud';
// Context
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Types
import { recordType } from '@appTypes/recipients';
// Other
import { getCheckboxLabel, getRecordsToOptions } from '@common-utils';

interface SelectAllCheckboxProps {
    id: string;
    selectedLabel: string;
    recordType: recordType;
}

const SelectAllCheckbox = ({ id, selectedLabel, recordType }: SelectAllCheckboxProps) => {
    const {
        state: { modalRecipients, recipientListData },
        dispatch,
    } = MessagingState();
    const activeLabelId = `${id}$${selectedLabel}`;

    const isCheckBoxChecked = (activeLabelId: string) => {
        return modalRecipients.modalRecipientOptions.some(option => option.isGroup && option.value === activeLabelId);
    };

    const onClickSelectAll = () => {
        const isChecked = isCheckBoxChecked(activeLabelId);

        const recordsToOptions = getRecordsToOptions(recipientListData.records, recordType);
        const modalRecipientOption = { value: activeLabelId, options: recordsToOptions, isGroup: true };
        const filteredModalRecipientOptions = modalRecipients.modalRecipientOptions.filter(
            option => !recordsToOptions.map(record => record.value).includes(option.value)
        );

        const newRecipientOptions = isChecked
            ? modalRecipients.modalRecipientOptions.filter(option => option.value !== activeLabelId)
            : [...filteredModalRecipientOptions, modalRecipientOption];

        dispatch({
            type: ActionType.SET_MODAL_RECIPIENTS,
            payload: { modalRecipientOptions: newRecipientOptions },
        });
    };

    let checkBoxIsDisabled = false;
    if (recordType === 'studentGuardianRecords' && recipientListData.records.length > 0) {
        const guardianRecords = recipientListData.records
            .map(record => (record.guardianRecords ? record.guardianRecords : []))
            .flat();
        checkBoxIsDisabled =
            guardianRecords.length == 0 ||
            guardianRecords.filter(record => record.allowSendingMessage === true).length === 0;
    } else {
        checkBoxIsDisabled = recipientListData.records.filter(record => record.allowSendingMessage === true).length === 0;
    }

    return (
        <div className={classNames('select-all-checkbox-floating', { disabled: checkBoxIsDisabled })}>
            <Checkbox
                label={getCheckboxLabel(recordType)}
                checked={isCheckBoxChecked(activeLabelId)}
                onChange={() => onClickSelectAll()}
                disabled={checkBoxIsDisabled}
            />
        </div>
    );
};

export default SelectAllCheckbox;
