// Vendors
import React from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Types
import { IModalRecipient, IRecord, recordType } from '@appTypes/recipients';
// Components
import SelectAllCheckbox from './SelectAllCheckbox';
import RecipientPersonButton from './RecipientPersonButton';
import RecipientPersonStudentsGuardians from './RecipientPersonStudentsGuardians';
// Other
import { StringOption } from '@components/WilmaAsyncSelect/WilmaAsyncSelect';
import { getGroupSelectionLabel, getUserDisplayName, splitLabel } from '@common-utils';

interface RecipientPersonProps {
    showElement: boolean;
    label: string;
    recordType: recordType;
    selectedSchoolId: string;
}

const RecipientPersons = ({ showElement, recordType, label, selectedSchoolId }: RecipientPersonProps) => {
    const {
        state: { modalRecipients, recipientListData, activeButtonId },
        dispatch,
    } = MessagingState();
    const { t } = useTranslation();

    const records = recipientListData.records;

    const onClickSelectRecipient = (event: React.MouseEvent<HTMLButtonElement>, record: IRecord) => {
        event.preventDefault();

        if (isSelected(record)) {
            handleUnselectRecipient(record);
        } else {
            handleSelectRecipient(record);
        }
    };

    const handleUnselectRecipient = (record: IRecord) => {
        // if unselected is part of ANY group in modalRecipientOptions, group selection will be set as unactive and group selection destructed
        const groupsThatHaveUnselectedUser = modalRecipients.modalRecipientOptions.filter(option =>
            option.options.some(personOption => personOption.value === record.roleGuid)
        );

        let remainingSingleRecipients: IModalRecipient[] = [];

        const groupValues = groupsThatHaveUnselectedUser.map(group => group.value);
        const filteredStringOptions = modalRecipients.modalRecipientOptions.filter(
            option => !groupValues.includes(option.value)
        );

        // for each group that has unselected user, create remaining options for that group
        groupsThatHaveUnselectedUser.forEach(group => {
            const stringOptionsInGroup = modalRecipients.modalRecipientOptions.filter(
                options => options.value === group.value
            );

            // map single recipients from group to be an array of single IModalRecipients
            const singleRecipientOptions = stringOptionsInGroup.flatMap(option => {
                return option.options.map(option2 => {
                    return {
                        value: option2.value,
                        options: [option2],
                        isGroup: false,
                    };
                });
            });
            // all the single selected recipients from the group that was previously selected as a part of the group, except the unselected person
            const remainingOptions: IModalRecipient[] = singleRecipientOptions.filter(
                option => option.value !== record.roleGuid
            );

            // add remaining single recipients from all unselected groups to array
            remainingSingleRecipients = [...remainingSingleRecipients, ...remainingOptions];
        });

        const remainingRecipients = [...filteredStringOptions, ...remainingSingleRecipients];

        const uniqueRecipients = remainingRecipients.filter(
            (user, index, self) => index === self.findIndex(t => t.value === user.value)
        );

        dispatch({
            type: ActionType.SET_MODAL_RECIPIENTS,
            payload: {
                modalRecipientOptions: uniqueRecipients,
            },
        });
    };

    const handleSelectRecipient = (record: IRecord) => {
        const recordToOption: StringOption = {
            label: record.label,
            value: record.roleGuid,
            name: record.name,
            nameAbbreviation: record.nameAbbreviation,
            allowMessaging: record.allowSendingMessage,
            roleType: record.roleType,
            schoolNames: record.schoolNames,
            extraInfo: record.extraInfo,
            isGroup: false,
        };
        const selectedStringOptions = [
            ...modalRecipients.modalRecipientOptions,
            { value: recordToOption.value, options: [recordToOption], isGroup: false },
        ];

        dispatch({
            type: ActionType.SET_MODAL_RECIPIENTS,
            payload: { modalRecipientOptions: selectedStringOptions },
        });
    };

    const isSelected = (record: IRecord) => {
        const isSelectedAsRecipient = modalRecipients.modalRecipientOptions.some(option =>
            option.options.some(personOption => personOption.value === record.roleGuid)
        );
        return isSelectedAsRecipient;
    };

    const hasGuardians = recipientListData.records.some(
        record => record.guardianRecords && record.guardianRecords.length > 0
    );

    if (recordType === 'studentRecords' && hasGuardians) {
        return (
            <RecipientPersonStudentsGuardians
                showElement={showElement}
                label={label}
                recordType={recordType}
                selectedSchoolId={selectedSchoolId}
                activeButtonId={activeButtonId}
                records={records}
                onClickSelectRecipient={onClickSelectRecipient}
                isSelected={isSelected}
            />
        );
    }

    const showSelectAllBox =
        records.length > 0 && recordType !== 'myStudentRecords' && recordType !== 'otherGuardiansRecords';

    return (
        <>
            {showElement && (
                <div className="list-group-recipients recipients-list">
                    <div>
                        {showSelectAllBox && (
                            <SelectAllCheckbox
                                id={activeButtonId}
                                selectedLabel={`${label} ${getGroupSelectionLabel(recordType, selectedSchoolId)}`}
                                recordType={recordType}
                            />
                        )}
                        {records?.map(record => {
                            const [name, role] = splitLabel(getUserDisplayName(record));
                            return (
                                <div
                                    key={record.roleGuid}
                                    className="button-row">
                                    <RecipientPersonButton
                                        name={name}
                                        role={role}
                                        record={record}
                                        key={`${record.roleGuid}-btn`}
                                        onClickSelectRecipient={onClickSelectRecipient}
                                        isSelected={isSelected}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {recipientListData.records?.length === 0 && (
                        <div className="recipient-list-text"> {t('selectRecipients.noRecipients')} </div>
                    )}
                </div>
            )}
        </>
    );
};

export default RecipientPersons;
