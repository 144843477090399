// Vendors
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Spinner } from '@vismaux/react-vud';
// Context
import { MessagingState } from '@context/Context';
// Types
import { IFolder } from '@appTypes/folder';
import { LoadingState } from '@appTypes/loadingstates';

interface Props {
    folder: IFolder;
    showIcons: boolean;
    showUnread: boolean;
}

const FolderListItem = (props: Props) => {
    const { t } = useTranslation();
    const folder = props.folder;
    const showUnread = props.showUnread;
    const {
        state: { unreadInboxCount, unreadInboxCountLoading },
    } = MessagingState();

    return (
        <div className="folder">
            {props.showIcons && (
                <Icon
                    name={folder.icon}
                    dynamic
                    size="sm"
                />
            )}
            <div className="folder-list-action-text">{t(folder.name)}</div>
            <>
                {showUnread && unreadInboxCountLoading === LoadingState.Loading && (
                    <span className="badge">
                        <Spinner
                            type="primary"
                            size="xs"
                        />
                    </span>
                )}
                {showUnread && unreadInboxCount > 0 && unreadInboxCountLoading === LoadingState.Done && (
                    <span className="badge">{unreadInboxCount}</span>
                )}
            </>
        </div>
    );
};

export default FolderListItem;
