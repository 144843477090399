// Vendors
import React from 'react';
// Context
import { IToast } from '@context/ToastContext';

interface ToastProps {
    toastList: IToast[];
}

const Toast = (props: ToastProps) => {
    const { toastList } = props;

    return (
        <>
            {toastList.map((toast, i) => (
                <div
                    key={i}
                    className={`toast toast-${toast.toastType}`}
                    role="alert">
                    {toast.title}
                </div>
            ))}
        </>
    );
};

export default Toast;
