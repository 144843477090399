// Vendors
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// Context
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Types
import { ITeacherRecords } from '@appTypes/recipients';
import { LoadingState } from '@appTypes/loadingstates';
// Services
import RecipientService from '@services/RecipientService';
// Other
import { RecipientSelectionTypes } from './RecipientsPersonList';

interface IMyTeachersCategoryButtonProps {
    recipientType: RecipientSelectionTypes;
    serviceMethod: () => Promise<ITeacherRecords>;
}

const MyTeachersCategoryButton = ({ recipientType, serviceMethod }: IMyTeachersCategoryButtonProps) => {
    const { t } = useTranslation();
    const {
        state: { activeButtonId },
        dispatch,
    } = MessagingState();

    const setActiveButtonId = (id: string) => {
        dispatch({
            type: ActionType.SET_ACTIVE_BUTTON_ID,
            payload: id,
        });
    };

    const onClickCategory = async (
        event: React.MouseEvent<HTMLButtonElement>,
        callFunction: () => Promise<ITeacherRecords>
    ) => {
        event.preventDefault();
        if (recipientType === activeButtonId) {
            return;
        }
        setActiveButtonId(recipientType);

        dispatch({
            type: ActionType.SET_RECIPIENTLIST_VIEW,
            payload: {
                showRecipientList: RecipientSelectionTypes.Loading,
                records: [],
            },
        });
        try {
            const records = await callFunction();
            if (RecipientService.loadingState !== LoadingState.Loading) {
                dispatch({
                    type: ActionType.SET_RECIPIENTLIST_VIEW,
                    payload: {
                        showRecipientList: recipientType,
                        records: records.teacherRecords,
                    },
                });
            }
        } catch {
            dispatch({
                type: ActionType.SET_RECIPIENTLIST_VIEW,
                payload: {
                    showRecipientList: RecipientSelectionTypes.Error,
                    records: [],
                },
            });
        }
    };

    return (
        <button
            className={classNames('recipient-list-group-item-1', { active: recipientType === activeButtonId })}
            onClick={e => {
                onClickCategory(e, serviceMethod);
            }}>
            <div className="recipient-list-text">{t(`selectRecipients.${recipientType}`)}</div>
        </button>
    );
};

export default MyTeachersCategoryButton;
