import { useState } from 'react';

export default function useRecipientModal() {
    const [isOpen, setisOpen] = useState(false);

    const toggleRecipients = () => {
        setisOpen(!isOpen);
    };

    return {
        isOpen,
        toggleRecipients,
    };
}
