// Vendors
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Spinner } from '@vismaux/react-vud';
// Context
import { MessagingState } from '@context/Context';
// Components
import RecipientPersons from './RecipientPersons';

export enum RecipientSelectionTypes {
    Students = 'students',
    Teacher = 'teachers',
    Personnel = 'personnel',
    TrainingCoordinators = 'trainingCoordinators',
    WorkplaceInstructors = 'workplaceInstructors',
    MyStudent = 'myStudent',
    OwnGuardians = 'ownGuardians',
    OtherGuardians = 'otherGuardians',
    OwnTeachers = 'ownTeachers',
    None = 'none',
    Loading = 'loading',
    Error = 'error',
}

const RecipientsPersonList = () => {
    const {
        state: { recipientListData, selectedSchoolId },
    } = MessagingState();
    const { t } = useTranslation();

    const showTeacherRecordsElement =
        recipientListData.showRecipientList === RecipientSelectionTypes.Teacher ||
        recipientListData.showRecipientList === RecipientSelectionTypes.OwnTeachers;

    const showOtherGuardiansRecordsElement =
        recipientListData.showRecipientList === RecipientSelectionTypes.OtherGuardians ||
        recipientListData.showRecipientList === RecipientSelectionTypes.OwnGuardians;

    return (
        <>
            <RecipientPersons
                showElement={recipientListData.showRecipientList === RecipientSelectionTypes.Students}
                recordType={'studentRecords'}
                label={recipientListData.currentClass ?? ''}
                selectedSchoolId={selectedSchoolId}
            />
            <RecipientPersons
                showElement={showTeacherRecordsElement}
                recordType={'teacherRecords'}
                label={recipientListData.currentSchool ?? ''}
                selectedSchoolId={selectedSchoolId}
            />
            <RecipientPersons
                showElement={recipientListData.showRecipientList === RecipientSelectionTypes.Personnel}
                recordType={'personnelRecords'}
                label={recipientListData.currentSchool ?? ''}
                selectedSchoolId={selectedSchoolId}
            />
            <RecipientPersons
                showElement={recipientListData.showRecipientList === RecipientSelectionTypes.TrainingCoordinators}
                recordType={'trainingCoordinatorRecords'}
                label={recipientListData.currentSchool ?? ''}
                selectedSchoolId={selectedSchoolId}
            />
            <RecipientPersons
                showElement={recipientListData.showRecipientList === RecipientSelectionTypes.WorkplaceInstructors}
                recordType={'workplaceInstructorRecords'}
                label={recipientListData.currentSchool ?? ''}
                selectedSchoolId={selectedSchoolId}
            />
            <RecipientPersons
                showElement={showOtherGuardiansRecordsElement}
                recordType={'otherGuardiansRecords'}
                label={recipientListData.currentSchool ?? ''}
                selectedSchoolId={selectedSchoolId}
            />
            <RecipientPersons
                showElement={recipientListData.showRecipientList === RecipientSelectionTypes.MyStudent}
                recordType={'myStudentRecords'}
                label=""
                selectedSchoolId={selectedSchoolId}
            />
            {recipientListData.showRecipientList === RecipientSelectionTypes.Loading && (
                <div className="center">
                    <Spinner />
                </div>
            )}
            {recipientListData.showRecipientList === RecipientSelectionTypes.Error && (
                <div className="center">
                    <Icon
                        name="error"
                        size="sm"
                    />
                    <span className="error-text">{t('errors.recipientLoadingFailed')}</span>
                </div>
            )}
        </>
    );
};

export default RecipientsPersonList;
