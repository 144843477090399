// Vendors
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// Hooks
import { useUpdateInboxUnreadCount } from '@hooks/useUpdateInboxUnreadCount';
// Components
import SearchBar from './Search';

const Layout = () => {
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();

    // Setup common layout initialization
    useEffect(() => {
        updateInboxUnreadCount();
    }, [updateInboxUnreadCount]);

    return (
        <>
            <div
                id="search-row"
                className="row">
                <div className="col-md-2" />
                <SearchBar />
            </div>
            <div
                id="main-row"
                className="row">
                <Outlet />
            </div>
        </>
    );
};

export default Layout;
