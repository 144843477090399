// Vendors
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon, ListContainer, ListGroup, Spinner } from '@vismaux/react-vud';
// Context
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Types
import { LoadingState } from '@appTypes/loadingstates';
import { IClassRecord, IIndexRecord } from '@appTypes/recipients';
// Services
import RecipientService from '@services/RecipientService';
// Components
import PrimusSearches from './PrimusSearches';
import IndexRecordsCategoryButton from './IndexRecordsCategoryButton';
// Other
import { RecipientSelectionTypes } from './RecipientsPersonList';

// react 18 does not have implicit children props but VUD uses react 16, need to declare children as React Nodes to prevent typescript errors
declare module '@vismaux/react-vud' {
    export interface IListGroupProps {
        title?: string | React.ReactNode;
        children: React.ReactNode;
    }

    export interface IListGroupItemProps {
        title?: string | React.ReactNode;
        children: React.ReactNode;
    }
}

const IndexRecordsCategory = () => {
    const {
        state: { selectedSchoolId, activeButtonId, currentUser, generalRecipientData },
        dispatch,
    } = MessagingState();
    const { t } = useTranslation();

    const setActiveButtonId = (id: string) => {
        dispatch({
            type: ActionType.SET_ACTIVE_BUTTON_ID,
            payload: id,
        });
    };

    const onClickClass = async (
        event: React.MouseEvent<HTMLButtonElement>,
        classId: string,
        currentClassName: string,
        currentSchoolName: string
    ) => {
        event.preventDefault();
        if (classId === activeButtonId) {
            return;
        }
        setActiveButtonId(classId);
        dispatch({
            type: ActionType.SET_RECIPIENTLIST_VIEW,
            payload: {
                showRecipientList: RecipientSelectionTypes.Loading,
                records: [],
            },
        });
        try {
            const response = await RecipientService.getRecipientsByClass(classId);
            if (RecipientService.loadingState !== LoadingState.Loading) {
                dispatch({
                    type: ActionType.SET_RECIPIENTLIST_VIEW,
                    payload: {
                        showRecipientList: RecipientSelectionTypes.Students,
                        records: response.studentRecords,
                        currentSchool: currentSchoolName,
                        currentClass: currentClassName,
                    },
                });
            }
        } catch {
            dispatch({
                type: ActionType.SET_RECIPIENTLIST_VIEW,
                payload: {
                    showRecipientList: RecipientSelectionTypes.Error,
                    records: [],
                },
            });
        }
    };

    const handleStudentButtonClick = (
        e: React.MouseEvent<HTMLButtonElement>,
        classRecord: IClassRecord,
        indexRecord: IIndexRecord
    ) => {
        return onClickClass(e, classRecord.id, classRecord.caption, indexRecord.caption);
    };

    const schoolRecords = generalRecipientData?.indexRecords?.filter(
        (record: IIndexRecord) => record.id === selectedSchoolId
    );
    return (
        <>
            {schoolRecords?.map((indexRecord: IIndexRecord) => {
                return (
                    <React.Fragment key={indexRecord.id}>
                        {(currentUser?.roleType === 'Teacher' || currentUser?.roleType === 'Personnel') && (
                            <ListContainer>
                                {RecipientService.primusSearchLoadingState === LoadingState.Done && <PrimusSearches />}
                                {RecipientService.primusSearchLoadingState === LoadingState.Error && (
                                    <div className="center">
                                        <Icon
                                            name="error"
                                            size="sm"
                                        />
                                        <span className="error-text">{t('errors.primusSearchesFetchFailed')}</span>
                                    </div>
                                )}
                                {RecipientService.primusSearchLoadingState === LoadingState.Loading && (
                                    <div className="center">
                                        <Spinner />
                                    </div>
                                )}
                            </ListContainer>
                        )}
                        <ListContainer>
                            {indexRecord.classRecords?.length > 0 && (
                                <ListGroup title={t('selectRecipients.students')}>
                                    {indexRecord.classRecords?.map((classRecord: IClassRecord) => {
                                        return (
                                            <div key={classRecord.id}>
                                                <button
                                                    className={classNames('recipient-list-group-item-2', {
                                                        active: classRecord.id === activeButtonId,
                                                    })}
                                                    onClick={e => handleStudentButtonClick(e, classRecord, indexRecord)}>
                                                    <div className="recipient">
                                                        <div className="recipient-list-text">{classRecord.caption}</div>
                                                    </div>
                                                </button>
                                            </div>
                                        );
                                    })}
                                </ListGroup>
                            )}
                        </ListContainer>
                        <ListContainer>
                            <IndexRecordsCategoryButton
                                indexRecord={indexRecord}
                                recipientType={RecipientSelectionTypes.Teacher}
                                serviceMethod={RecipientService.getTeachersInSchool.bind(RecipientService)}
                            />
                        </ListContainer>
                        <ListContainer>
                            <IndexRecordsCategoryButton
                                indexRecord={indexRecord}
                                recipientType={RecipientSelectionTypes.Personnel}
                                serviceMethod={RecipientService.getPersonnelInSchool.bind(RecipientService)}
                            />
                        </ListContainer>
                        <ListContainer>
                            <IndexRecordsCategoryButton
                                indexRecord={indexRecord}
                                recipientType={RecipientSelectionTypes.TrainingCoordinators}
                                serviceMethod={RecipientService.getTrainingCoordinatorsInSchool.bind(RecipientService)}
                            />
                        </ListContainer>
                        <ListContainer>
                            <IndexRecordsCategoryButton
                                indexRecord={indexRecord}
                                recipientType={RecipientSelectionTypes.WorkplaceInstructors}
                                serviceMethod={RecipientService.getWorkplaceInstructorsInSchool.bind(RecipientService)}
                            />
                        </ListContainer>
                        <ListContainer>
                            <button className="recipient-list-group-item-1 disabled">
                                <div className="recipient-list-text">{t('selectRecipients.favorites')}</div>
                            </button>
                        </ListContainer>
                        <ListContainer>
                            <button className="recipient-list-group-item-1 disabled">
                                <div className="recipient-list-text">{t('selectRecipients.ownGroups')}</div>
                            </button>
                        </ListContainer>
                        {currentUser?.roleType === 'Teacher' ||
                            (currentUser?.roleType === 'Personnel' && (
                                <ListContainer>
                                    <button className="recipient-list-group-item-1 disabled">
                                        <div className="recipient-list-text">{t('selectRecipients.primusSearch')}</div>
                                    </button>
                                </ListContainer>
                            ))}
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default IndexRecordsCategory;
